import React from 'react';

import { Layout } from 'antd';

import SideBar from 'containers/SideBar';

import { MainAntLayout, ContentAntLayout, AntSider } from './styles';

type Props = {
  orderName: string;
};

const { Content } = Layout;

const MenuLayout: React.FC<Props> = ({ children, orderName }) => {
  return (
    <MainAntLayout>
      <ContentAntLayout style={{ overflowY: 'scroll' }}>
        <Content>{children}</Content>
      </ContentAntLayout>
      <AntSider>
        <SideBar orderName={orderName} />
      </AntSider>
    </MainAntLayout>
  );
};

export default MenuLayout;
