import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { AuthContext } from 'authContext';
import { User } from 'codegen/generated/graphql';
import Button from 'components/Button';
import { Logo } from 'components/Image';
import { Row } from 'components/Layout';
import Text from 'components/Text';
import Routes from 'router/routes';
import { getKioskDeliveryAddress } from 'utils/helpers';

interface Props {
  orderName: string;
}

const CheckoutHeader = ({ orderName }: Props) => {
  const { push } = useHistory();
  const { user, isDelivery } = useContext(AuthContext);
  const deliveryAddress = getKioskDeliveryAddress(user as User, isDelivery);

  return (
    <>
      <Row $padding="50px 0 50px 0" $width="100%" $minHeight="150px" $flex={0}>
        <Button
          icon={
            <>
              <ArrowLeftOutlined />
              <Text fontSize="sm" fontWeight="bold" marginLeft="md">
                Go Back
              </Text>
            </>
          }
          onClick={() => push(Routes.MENU.path)}
          position="absolute"
          left="55px"
          border={0}
          skeleton
          flexDirection="row"
          size="middle"
        />

        <Logo
          src={`${user?.facilities[0].kioskLogoUrl ?? '/svg/crave-black.svg'}`}
          alt="Logo"
          objectFit="contain"
          $marginTop="none"
          $borderRadius="none"
          $maxHeight="130px"
        />
      </Row>
      <Text fontSize="h2" fontWeight="bold" marginLeft={90} spaceAfter="md">
        Your Order
      </Text>
      {isDelivery && (
        <Text fontWeight="bold" marginLeft={90} spaceAfter="md">
          {deliveryAddress ? deliveryAddress.line1 : ''}{' '}
          {deliveryAddress ? deliveryAddress.line2 : ''}
        </Text>
      )}
    </>
  );
};

export default CheckoutHeader;
