import styled from 'styled-components';

import { theme } from 'theme';

export const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
`;

export const TopCheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.color.eggshell};
`;

export const OrderSummaryWrapper = styled.div`
  flex: 1;
  display: flex;
  overflow-y: scroll;
  width: 90%;
  padding: 40px;
  margin: 0 auto;
  min-height: 30vh;
  background-color: ${theme.color.white};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 40px;
  padding: 30px 90px;
`;

export const CartUpsellItemsWrapper = styled.div`
  margin: 0 92px;
`;
