import React, { FC, useContext, createContext, useState } from 'react';

import { format, isAfter, isBefore, isEqual } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import { AuthContext } from 'authContext';
import {
  useAvailableTimeslotsLazyQuery,
  useAvailableTimeslotsQuery,
  OrderMethod,
} from 'codegen/generated/graphql';
import { FacilityContext } from 'utils/context/facilityContext';

interface Props {
  isClosed: boolean;
  setIsClosed: (value: boolean) => void;
  timeToReopen: Date;
}

export const HoursContext = createContext<Props>({
  isClosed: false,
  setIsClosed: (_) => undefined,
  timeToReopen: new Date(),
});

export const useHoursContext = () => useContext(HoursContext);

const HoursContextProvider: FC = ({ children }) => {
  const [isClosed, setIsClosed] = useState(false);
  const [timeToReopen, setTimeToReopen] = useState(new Date());
  const { facilityTz } = useContext(FacilityContext);
  const { isDelivery, user } = useContext(AuthContext);
  //Get today's date and tomorrow's in case there are no timeslots today
  const todaysDate = new Date();
  const tomorrowsDate = new Date();
  const tomorrow = new Date(tomorrowsDate);
  tomorrow.setDate(tomorrow.getDate() + 1);

  //Round time so variable isn't constantly changing and re-rendering
  const timeToRound = 1000 * 60 * 1;
  const rounded = new Date(
    Math.round(todaysDate.getTime() / timeToRound) * timeToRound,
  );
  const tomorrowRounded = new Date(
    Math.round(tomorrow.getTime() / timeToRound) * timeToRound,
  );

  const deliveryMethod = isDelivery ? OrderMethod.Delivery : OrderMethod.PickUp;

  //Get timeslots for tomorrow in case today is closed
  const [getTomorrow, { data: tomorrowOpen }] = useAvailableTimeslotsLazyQuery({
    variables: {
      orderMethod: deliveryMethod,
      date: format(tomorrowRounded, 'yyyy-MM-dd'),
    },
  });

  const fetchTomorrowAndProcess = () => {
    getTomorrow();
    const tomorrowTime = tomorrowOpen?.customerTimeslots[0]?.start;
    if (!tomorrowTime) return;
    const tomorrowTimeFormatted = utcToZonedTime(tomorrowTime, facilityTz);
    setTimeToReopen(tomorrowTimeFormatted);
  };

  //Get timeslots available today (checking for overmethod overrides)
  const { data: times } = useAvailableTimeslotsQuery({
    variables: {
      orderMethod: deliveryMethod,
      date: format(rounded, 'yyyy-MM-dd'),
    },
    skip: !user, // Skip if user is not logged in to reduce GCP error logs
    pollInterval: 15 * 1000,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const timeSlots = data.customerTimeslots || [];
      const firstTimeslot = times?.customerTimeslots[0];
      const lastTimeslot = times?.customerTimeslots[timeSlots.length - 1];

      // if no timeslots available for today, fetch the ones for tomorrow
      if (!firstTimeslot || !lastTimeslot) {
        setIsClosed(true);

        return fetchTomorrowAndProcess();
      }

      const localizedNow = utcToZonedTime(rounded, facilityTz);

      const firstSlotTime = utcToZonedTime(firstTimeslot.start, facilityTz);
      const lastSlotTime = utcToZonedTime(lastTimeslot.end, facilityTz);

      const isStartAfterOrEqual =
        isAfter(localizedNow, firstSlotTime) ||
        isEqual(localizedNow, firstSlotTime);

      const isEndBefore = isBefore(localizedNow, lastSlotTime);

      const isWithinOpeningHours = isStartAfterOrEqual && isEndBefore;

      setIsClosed(!isWithinOpeningHours);
      setTimeToReopen(firstSlotTime);
    },
  });

  return (
    <HoursContext.Provider
      value={{
        isClosed,
        setIsClosed,
        timeToReopen,
      }}
    >
      {children}
    </HoursContext.Provider>
  );
};

export default HoursContextProvider;
