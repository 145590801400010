import React, { FC, ReactNode, useEffect, useState } from 'react';

import { Spin } from 'antd';
import { ThemeProvider } from 'styled-components';

import { theme } from '../theme';
import { Tenant } from 'codegen/generated/graphql';
import {
  fetchTenant,
  getTenantFromStorage,
  setMetaDataFromTenant,
  setTenantInStorage,
} from 'utils/tenant';

import LandingLayout from '../containers/LandingLayout';
import { GlobalStyle } from '../theme/GlobalStyle';

const PageLoader = () => {
  return <Spin tip="Loading..." spinning />;
};

export const TenantGuard: FC<{
  children: (props: { tenant: Tenant }) => ReactNode;
}> = ({ children }) => {
  const [tenant, setTenantId] =
    useState<Tenant | undefined>(getTenantFromStorage);
  const [loading, setLoading] = useState(!tenant);

  const loadTenantId = async () => {
    setLoading(true);
    try {
      const tenant = await fetchTenant();

      setTenantId(tenant);
      setTenantInStorage(tenant);
    } catch (e) {
      // TODO: Handle error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!tenant?.id) {
      loadTenantId();
    }
  }, [tenant?.id]);

  useEffect(() => {
    tenant && setMetaDataFromTenant(tenant);
  }, [tenant]);

  if (loading && !tenant) {
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <LandingLayout>
        <PageLoader />
      </LandingLayout>
    </ThemeProvider>;
  }

  if (tenant && !loading) {
    return <>{children({ tenant })}</>;
  } else {
    return <>Error - cannot get tenant id</>;
  }
};
