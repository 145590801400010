import {
  ApolloClient,
  from,
  createHttpLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { auth } from '../utils/firebase';
import { cache } from './apollo/cache';
import { getTenantFromStorage } from './tenant';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  // eslint-disable-next-line no-console
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

const getHeaders = async () => {
  const token = await auth.currentUser?.getIdToken();
  const facilityId = localStorage.getItem('facilityId');
  const facilityTz = localStorage.getItem('facilityTz');

  return {
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      platform: 'kiosk',
      tenant_id: getTenantFromStorage()?.id,
      timezone: facilityTz || '',
      facility_id: facilityId || '',
    },
  };
};

const authLink = setContext(getHeaders);

const httpLink = createHttpLink({
  uri:
    process.env.REACT_APP_KIOSK_GRAPHQL_API_URL ||
    'http://localhost:4000/graphql',
});

export const initApollo = () => {
  const client = new ApolloClient({
    link: from([authLink as any, errorLink, httpLink]),
    cache,
  });

  return client;
};

export let client: ApolloClient<NormalizedCacheObject>;
export const reinitializeApolloClient = () => {
  client = initApollo();

  return client;
};
